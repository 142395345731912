<template>
    <p> <a class="fa-brands fa-linkedin fa-2xl" href="https://www.linkedin.com/in/andreas-alstrup-3607a619a"></a> Andreas Alstrup</p>
    <router-link to="/" class="link-success" style="font-size: 24px; text-decoration: none;">Back</router-link>
</template>

<style scoped>
a {
    text-decoration: none;
    color: #0A66C2;
}
</style>